@import "../../assets/css/colors.scss";

.button {
  border: none;
  outline: none;
  width: 116px;
  height: 33px;
  font-size: 16px;
  font-weight: 500;
  color: $white;
  border-radius: 4px;
  cursor: pointer;
  transition: all 600ms ease-in-out;
  &.primaryBtn {
    background: $newLightGrey;
    &:hover {
      background: $newLightGrey;
    }
  }
}
