@import "../colors.scss";

.searchContainer {
  margin: 0 24px 0 36px;
  .searchWrapper {
    background-color: $newLightGrey;
    width: 254px;
    height: 38px;
    // border: 2px solid $white;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    transition: all 500ms ease-in-out;
    // &:focus-within {
    //   border: 2px solid $pink;
    //   label {
    //     i {
    //       color: $pink;
    //     }
    //   }
    // }
    label {
      i {
        color: $newDarkBlue;
        position: absolute;
        top: 50%;
        left: 20px;
        transform: translateY(-50%);
        transition: all 500ms ease-in-out;
      }
    }
    input {
      font-size: 12pt;
      font-weight: 600;
      width: 100%;
      height: 100%;
      padding-left: 46px;
      background: transparent;
      border: none;
      outline: none;
      color: $newDarkBlue;
      &::placeholder {
        color: $newDarkBlue;
      }
    }
  }
}


@media (max-width: 540px) {
  .searchContainer{
    display: fixed;
    z-index: 1000;
    margin-top: -40px;
    margin-left: 125px;
    .searchWrapper {
        width: 55vw;
      }
  }
}