@import "../../../assets/css/colors.scss";

.nav {
  width: 100%;
  padding: 15px;
  background: $newDarkBlue;
  z-index: 11;
  position: relative;

  .navContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    max-width: none;
    .navMenu {
      display: flex;
      align-items: center;

      .logo {
        text-decoration: none;
        color: $white;
        text-shadow: $pink 3px 3px 1px;
        font-size: 28px;
        font-weight: 700;
      }

      .navLinks {
        display: flex;
        align-items: center;

        a {
          margin: 0 24px;
          text-decoration: none;
          display: inline-block;
          color: $white;
          font-size: 22px;
          font-weight: 500;
          transition: all 600ms ease-in-out;

          &:first-of-type {
            margin: 0;
          }

          &:hover,
          &.activeLink {
            color: $pink;
          }
        }
      }
    }

    .btnWrapper {
      display: flex;
      align-items: center;
    }

    .button {
      width: 18vw;
      text-align: center;
    }
  }
}

.contentAlignCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $dicoverOrangeGradient;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.7);
  margin: 10px 15px 10px 15px;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 500ms ease-in-out;
  font-weight: 400;
  font-family: "Nunito", sans-serif;
  height: 6vh;

  .activeBtn {
    color: $white;
    text-decoration: none;
    font-size: larger;
  }
}

.mobileSearchButton{
  background-color: transparent;
  border: none;
  border-radius: 2px;
  color: $newLightGrey;
  margin-left: 20px;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

@media (max-width: 1050px) {
  .nav {
    .navContainer {
      .navMenu {
        .mobileMenu {
          padding: 0 5px 0px 0px;
          width: 220px;
          background: $newDarkBlue;
          position: absolute;
          top: 58px;
          right: 0;
          z-index: 101;
          display: flex;
          flex-direction: column;
          align-items: center;
          height: 0;
          overflow: hidden;
          transition: padding 0.3s ease;

          .navLinks {
            margin: 20px 0 0 0;
            flex-direction: column;
            align-items: center;

            a {
              &:last-of-type {
                margin: 10px 0 0 0;
              }
            }
          }

          &.mobileMenuOpen {
            padding: 10px 10px 0px 10px;
            height: auto;
            overflow: visible;
          }
        }
      }

      .closeIcon {
        color: $white;
        cursor: pointer;
        font-size: 26px;
        margin-left: 6px;
      }
    }
  }
}