@import "../../assets/css/colors.scss";

.footer {
  padding: 60px 0;
  width: 100%;
  background: $newDarkBlue;
  .footerContainer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    .footerRow {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .logo {
        margin-bottom: 20px;
        color: $white;
        text-shadow: $pink 3px 3px 1px;
        font-size: 40px;
        font-weight: 700;
      }
      h4 {
        margin: 0 0 20px 0;
        line-height: 1;
        color: $white;
        font-size: 40px;
        font-weight: 500;
      }
      p {
        margin: 0;
        color: $white;
        font-size: 16px;
        font-weight: 200;
        &:not(:first-of-type) {
          margin: 12px 0 0 0;
        }
      }
      a {
        text-decoration: none;
        color: $white;
        font-size: 20px;
        font-weight: 300;
        line-height: 1;
        display: flex;
        align-items: center;
        transition: all 500ms ease-in-out;
        &:not(:first-of-type) {
          margin: 12px 0 0 0;
        }
        &:hover {
          color: $pink;
        }
        i {
          min-width: 20px;
          font-size: 20px;
          margin-right: 14px;
        }
      }
    }
  }
}

@media (max-width: 585px) {
  .footer {
    padding: 30px;
    .footerContainer {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 40px;
    }
  }
}

@media (min-width: 586px) and (max-width: 1232px) {
  .footer {
    padding: 40px;
    .footerContainer {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 40px;
    }
  }
}
