.mainContainer {
  width: 100%;
  max-width: 1360px;
  margin: 0 auto;

  @media (max-width: 469px) {
      margin: 50px auto;
      &:first-of-type {
        margin: 0 auto;
      }
  }
}

// @media (max-width: 1420px) {
//   .mainContainer {
//     max-width: 94%;
//   }
// }
