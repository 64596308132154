$siteBg: linear-gradient(90deg,
    rgba(32, 35, 128, 1) 30%,
    rgba(120, 41, 131, 1) 70%);

$newLightBlue: #DEE4FC; 
$newDarkBlue: #012060;
$newLightGrey: #7a7eb2;
$dbButtons: #8e92c1;
$newRed: #b66769;
$errorRed: #8a0f12;

$white: #fff;
$offWhite: #f1f2ff;
$offWhite1: #fafafa;
$black: #000;
$grey: #293049;
$grey1: #1b1f2e;
$lightGrey: #d9d3d3;
$pink: #ca40ba;
$pink1: #9e1cb3;
$purple: #782983;
$darkPurple: #4b2682;
$darkPurple1: #4a2581;
$lightGreen: #00e7ad;
$lightBlue: #41e6e1;
$blue: #293ea9;
$blue1: #20207b;
$blue2: #1b1833;
$blue3: #272349;
$blue4: #1b2c83;
$blue5: #180a58;
$blue6: #1b1464;
$blue7: #202380;
$red: #a71212;
$errorColor: #f67373;

$blueGradientDL: linear-gradient(130deg, #180a58, #293ea9);
$blueGradientLD: linear-gradient(130deg, #293ea9, #180a58);
$blueGradientLDLight: linear-gradient(130deg, #293ea96e, #180a5867);
$blueGradientLDV: linear-gradient(180deg, #293ea9, #180a58);

$purplePinkGradient: linear-gradient(90deg, #4e0546, #ca40ba);
$purplePinkGradientHover: linear-gradient(90deg, #470440, #c53eb5);

$bluePurpleGradient: linear-gradient(45deg, #202380 30%, #692782 70%);
$bluePurpleGradient1: linear-gradient(135deg, #202380 30%, #692782 70%);

$dicoverOrangeGradient: linear-gradient(90deg,
    rgba(254, 96, 31, 1) 0%,
    rgba(234, 20, 65, 1) 52%,
    rgba(185, 18, 156, 1) 100%);

$timelineGradient: linear-gradient(to right, #08ffd1 0%, #c83b94 100%);

$republican: rgba(#ff3333, 1);
$center: rgba(#8e04ce, 1);
$democrat: rgba(#496dff, 1);

$blackTransparent: #302d2dcf