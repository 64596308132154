@import "../../../assets/css/colors.scss";

.searchResults {
  min-width: 360px;
  width: 360px;
  height: auto;
  position: absolute;
  top: 60px;
  left: 0;
  z-index: 30;
  background: $white;
  border-radius: 10px;
  box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.75);
  .searchResultsWrapper {
    width: 100%;
    padding: 0 14px;
    > h4 {
      margin: 12px 0;
      color: $black;
    }
    .searchResultsInfo {
      a {
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: $black;
        &:hover {
          text-decoration: none;
        }
        &:not(:first-of-type) {
          margin: 10px 0 0 0;
        }
        img {
          width: 36px;
          height: 36px;
          border-radius: 50px;
          display: block;
          object-fit: cover;
        }
        p {
          padding-left: 14px;
          margin: 0;
          font-size: 15px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          line-height: 1.1;
        }
      }
    }
  }
}

@media (max-width: 984px) {
  .searchResults {
    min-width: 100%;
    width: 100%;
    top: 50px;
  }
}

//THIS IS FROM CHANNEL CARDS
.channelThumbnailWrapper {
  background-color: #fff;
  width: 250px;
  height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border-radius: 8px 8px 8px 8px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.8) 0px 0 10px;
  -moz-box-shadow: rgba(0, 0, 0, 0.8) 0 0 10px;
  box-shadow: rgba(0, 0, 0, 0.8) 0 0 10px;

  .channelDefaultTitle {
    align-items: center;
    color: $purple;
    font-size: 6.5rem;
  }
}