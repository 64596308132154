@import "../../../assets/css/colors.scss";

.container{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: 0;
  right: 0;
  width: 450px;
  height: 670px;
  z-index: 300;
  background-color: $newLightBlue;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 15px;
  border: solid;
  border-color: white;
  border-width: 5px;
}

.header{
  background-image: $dicoverOrangeGradient;
  width: 100%;
  height: 65px;
  border-radius: 10px 10px 0px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}

.blurBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);  /* Adjust the blur amount as needed */
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.closeModalButton{
  margin-left: 410px;
  margin-top: -10;
  font-size: 16pt;
  width: 20px;
  height: 20px;
  background-color: transparent;
  color: white;
  border: none;
  padding: 0;
  cursor: pointer;
}

.pfp{
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-top: 15px;
}

.channelName{
  font-size: 18pt;
  color: $newDarkBlue;
  font-weight: bolder;
  margin: 10px 0px 10px 0px;
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto auto;
  /* Two rows of auto height */
  gap: 10px;
  /* Adjust the gap between grid items as needed */
  border-top: 2px solid;
  border-bottom: 2px solid;
  border-left: 0;
  border-right: 0;
  border-color: $newDarkBlue;

  margin-left: 10px;
  margin-right: 10px;
  padding-bottom: 10px;
}

.gridItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
  padding: 5px;
  text-align: center;
}

.spanTwoColumns {
  text-align: center;
  grid-column: span 2;
  font-size: 18pt;
  /* Spans across 2 columns */
  color: $newDarkBlue;
  font-weight: bolder;
  margin: 10px 0 5px 0;
}


.descriptions {
  color: $newDarkBlue;
  margin-bottom: 0px;
  margin-top: 4px;
}

.subscriptionTier {
  font-size: 16pt;
  color: $newDarkBlue;
  font-weight: bolder;
  margin: 10px 0px 10px 0px;
}

.subscriptionPrice {
  font-size: 12pt;
  color: $newLightGrey;
  margin: 0px;
  border-bottom: 2px solid $newDarkBlue;
}

.cancel {
  font-size: 10pt;
  color: $newLightGrey;
  margin: 0px;
}

.row{
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
    margin: 0px 0px 20px 0px;
}

.subscribeButton{
  color: white;
  margin-right: 10px;
  border: none;
  height: 30px;
  width: 160px;
  border-radius: 10px;
  font-size: 15px;
  cursor: pointer;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.5);
  background-image: $dicoverOrangeGradient;
}

.footer {
  font-size: 12pt;
  color: $newLightGrey;
  margin: 10px 0px 0px 0px;
}

.stepOneIcon {
  width: 50px;
  height: 50px;
  background-size: contain;
  background-repeat: no-repeat;
}

.badge {
  composes: stepOneIcon;
  background-image: url('./../../../assets/PartnerBadge.svg');
}

.chatBubble {
  composes: stepOneIcon;
  background-image: url('./../../../assets/ChatBubble.svg');
}

.videoPlayer {
  composes: stepOneIcon;
  background-image: url('./../../../assets/VideoPlayer.svg');
}

.support {
  composes: stepOneIcon;
  background-image: url('./../../../assets/Support.svg');
}

.exclusive {
  composes: stepOneIcon;
  background-image: url('./../../../assets/Exclusivie.svg');
}

.stepTwoIcon {
  width: 30px;
  height: 30px;
  background-size: contain;
  background-repeat: no-repeat;
}

.heart{
  composes: stepTwoIcon;
  background-image: url('./../../../assets/Heart.svg');
}

.trophy{
  composes: stepTwoIcon;
  background-image: url('./../../../assets/Trophy.svg');
}

.calendar{
  composes: stepTwoIcon;
  background-image: url('./../../../assets/Calendar.svg');
}

.stepTwoGrid{
  display: grid;
  grid-template-columns: 1fr 4fr;
    /* First column takes 1/4, second column takes 3/4 */  /* 2 columns of equal width */
  grid-template-rows: repeat(3, auto);
  /* 3 rows with auto height */
  gap: 10px;
  /* Gap between grid items */
  border-top: 2px solid;
  border-bottom: 2px solid;
  border-left: 0;
  border-right: 0;
  border-color: $newDarkBlue;

  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-left:-50px;
}

.buttonContainer{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  padding: 20px;
  overflow-x: wrap;
  overflow: scroll;
}
