@import "../../../assets/css/colors.scss";

.socialLogin {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 80px;
  margin-bottom: 15px;
  // margin-left: -5px;

  p {
    text-align: center;
    padding-top: 20px;
    color: $white;
    width: 100px;
    margin-right: 15px
  }

  img {
    cursor: pointer;
    width: 10%;

    
    &:not(:last-child) {
      margin-right: 15px
    }
  }

  .twitterIcon {
    width: 100%;
  }

  .twitterBtn {
    width: 10%;
  }
}

.signupModal {
  padding: 0 20px 20px 20px;

  form {
    .signupInputWrapper {
      input {
        margin: 14px 0 0 0;
        background: transparent;
        padding: 0 16px;
        width: 100%;
        height: 40px;
        border-radius: 50px;
        border: none;
        border: 1px solid $white;
        outline: none;
        transition: all 500ms ease-in-out;
        color: $white;
        font-weight: 500;

        &::placeholder {
          font-weight: 500;
          color: $white;
          transition: all 500ms ease-in-out;
        }

        &:focus {
          border: 1px solid $pink;
        }

        &.userNameInput {
          margin: 0;
        }
      }

      .signupBtnWrapper {
        padding: 14px 0 0 0;

        button {
          border: none;
          outline: none;
          width: 100%;
          height: 42px;
          border-radius: 50px;
          background: $purple;
          color: $white;
          font-size: 20px;
          transition: all 500ms ease-in-out;
          cursor: pointer;

          &:hover {
            background: darken($color: $purple, $amount: 10%);
          }

          span {
            line-height: 1;
            margin: 0 10px 3px 0;
          }
        }

        p {
          margin: 16px 0 0 0;
          color: $white;
          text-align: left;

          span {
            color: $pink;
            font-weight: 800;
            cursor: pointer;
          }
        }
      }
    }

    .errorMsg {
      width: 100%;

      p {
        margin: 14px 0 0 0;
        word-break: break-word;
        color: $errorColor;
        font-size: 14px;
        font-weight: 400;
        line-height: 1;
      }
    }
  }

  .signupInfo {
    padding: 14px 0 0 0;
    width: 100%;
    text-align: center;

    p {
      margin: 0;
      font-size: 13px;
      line-height: 1.4;
      color: $offWhite;

      span {
        a {
          color: $pink;
          font-weight: 700;
        } 
      }
    }
  }
}