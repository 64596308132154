@import "../css/colors.scss";

.loader {
  visibility: hidden;
  position: absolute !important;
  opacity: 1;
  z-index: 330 !important;
  top: 50%;
  left: 50%;
}

* {
  box-sizing: border-box;
  font-family: "Nunito", sans-serif;
}

// .mainSection {
//   // padding-top: 70px;
// }

body {
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100vh;
  background: $newDarkBlue;
}

// .scroller {
//   scroll-behavior: smooth;
//   margin-right: 0;

//   &::-webkit-scrollbar-track {
//     cursor: pointer;
//   }

//   &::-webkit-scrollbar {
//     width: 12px;
//     height: 10px;
//     background: $white;
//     cursor: pointer;
//   }

//   &::-webkit-scrollbar-thumb {
//     background: $grey;
//     background-clip: padding-box;
//   }
// }