@import "../../../assets/css/colors.scss";

.forgotPasswordModal {
  padding: 0 20px 20px 20px;
  form {
    input {
      background: transparent;
      padding: 0 16px;
      width: 100%;
      height: 40px;
      border-radius: 50px;
      border: none;
      border: 1px solid $white;
      margin: 0;
      outline: none;
      transition: all 500ms ease-in-out;
      color: $white;
      font-weight: 500;
      &::placeholder {
        font-weight: 500;
        color: $white;
        transition: all 500ms ease-in-out;
      }
      &:focus {
        border: 1px solid $pink;
      }
    }
    .forgotPasswordBtnWrapper {
      padding: 14px 0 0 0;
      .resetBtn {
        border: none;
        outline: none;
        width: 100%;
        height: 42px;
        border-radius: 50px;
        background: $purple;
        color: $white;
        font-size: 20px;
        cursor: pointer;
        transition: all 500ms ease-in-out;
        &:hover {
          background: darken($color: $purple, $amount: 10%);
        }
        span {
          line-height: 1;
          margin: 0 10px 3px 0;
        }
      }
      p {
        margin: 16px 0 0 0;
        color: $white;
        text-align: left;
        cursor: pointer;
      }
    }
  }
  .errorMsg {
    width: 100%;
    p {
      margin: 14px 0 0 0;
      color: $errorColor;
      font-size: 14px;
      font-weight: 400;
      line-height: 1;
    }
  }
}


@media (max-width: 984px) {
  .resetBtn { 
    width: 100% !important;
  }
}