/* homepage.module.scss */
@import "../../assets/css/colors.scss";
/* homepage.module.scss */
.containerPadding {
  padding-left: 15px;
  background-color: transparent;
  padding-right: 15px;

}

.sidebar {
  display: flex;
  flex-direction: column;
  flex: 0 0 200px;
  background-color: $newLightBlue;
  height: 100%;
  width: 200px;
  justify-content: flex-start;
  /* Set the height to 100% of the parent container (homepageContainer) */
}

.sidebarItem {
  cursor: pointer;
  padding: 5px 0px 5px 30px;
  font: 'Nunito Medium';
  font-weight: 500;
  font-size: 15pt;
  color: $newLightGrey;
}

.sidebarItem:hover {
  background-color: #b5bbe4;
}

.sidebarTitle {
  cursor: pointer;
  padding: 2px;
  padding-left: 5px;
  font: 'Nunito Medium';
  font-weight: 500;
  font-size: 18pt;
}

.sidebarTitle:hover {
  background-color: #b5bbe4;
}

.liveNow {
  cursor: pointer;
  padding: 2px;
  padding-left: 5px;
  font: 'Nunito Medium';
  font-weight: 500;
  font-size: 18pt;
}


.login {
  padding-top: 16px;
  padding-left: 40px;
  padding-bottom: 16px;
  font: 'Nunito Medium';
  font-weight: 500;
  font-size: 14pt;
  color: $newLightGrey;
}



.liveNowStreamersList {
  margin: 0 0 0 0;
  padding-left: 4px;
  padding-bottom: 16px;
  cursor: pointer;
  list-style: none;
}

.liveNowStreamer {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  color: $newLightGrey;
  font-size: 15px;
  align-items: center;
}

.liveNowStreamer:hover {
  background-color: #b5bbe4;
}

.channelLogo {
  width: 25px;
  height: 25px;
  margin-right: 10px;
  border-radius: 50%;
  object-fit: cover;
}

@media (max-width: 1050px) {
  .sidebar {
    display: none;
  }
  .containerPadding {
    padding: 0px;
  }
}



.toggleButton{
  margin-left: 10px;
  background-color: black;
  border: 1px solid black;
  border-radius: 10px;
  width: 40px;
  height: 20px;
  transition: background-color 0.5s ease, border-color 0.5s ease;
  cursor: pointer;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.748);
  position: relative;
}

.toggleButton .thumb{
  height: 15px;
  width: 15px;
  background-color: white;
  border-radius: 100px;
  transform: translateX(0);
  transition: left 0.15s ease;
  position: absolute;
  left: 3px;
  top: 50%;
  transform: translateY(-50%);
}

.toggleButton.toggled{
  background-color: white;
}

.toggleButton.toggled .thumb{
  left: calc(100% - 19px);
  background-color: black;
}

.darkModeWrapper{
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  color: $newDarkBlue;
  font-size: 13px;
  align-items: center;
  text-align: center;
}