/* homepage.module.scss */
@import "../../assets/css/colors.scss";
/* homepage.module.scss */
.containerPadding {
  padding-left: 15px;
  background-color: transparent;
  padding-right: 15px;

}

.sidebar {
  background-color: $newLightBlue;
  height: 100%;
  width: 200px;
  justify-content: center;
  /* Set the height to 100% of the parent container (homepageContainer) */
}

@media (max-width: 1300px) {
  .sidebar {
    display: none;
  }
  .containerPadding {
    padding: 0px;
  }
}

.sidebarTitle {
  margin: 0 0 0 0;
  padding: 10px 2px 2px 2px;
  padding-left: "5px";
  font: 'Nunito Medium';
  font-weight: 500;
  font-size: 15pt;
  color: $newDarkBlue;
  display: flex;
  align-items: center;
}

.trendingStreamersList{
  padding-top: 10px;
  margin: 0 0 0 0;
  padding-left: 5px;
  cursor: pointer;
  list-style: none;
}

.trendingStreamer{
  cursor: pointer;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  color: $newLightGrey;
  font-size: 15px;
}

.trendingStreamer:hover {
  background-color: #b5bbe4;
}

.listNumber{
  padding-right: 20px;
  padding-left: 2px;
  color: $newDarkBlue
}


.trendingTopicsList {
  margin: 0 0 0 0;
  padding-left: 15px;
  cursor: pointer;
  list-style: none;
}

.trendingTopic {
  text-align: center;
  padding-left: 8px;
  padding-right: 8px;
  max-width: 120px;
  width: fit-content;
  margin-left: 16px;
  margin-bottom: 10px;
  cursor: pointer;
  border-radius: 25px;
  color: white;
}

/* Update the selectors to target the li elements inside the Link components */
.trendingTopicsList>a:nth-child(1) li {
  background-color: #cf8686;
}

.trendingTopicsList>a:nth-child(2) li {
  background-color: #688564;
}

.trendingTopicsList>a:nth-child(3) li {
  background-color: #6b89e1;
}

.trendingTopicsList>a:nth-child(4) li {
  background-color: #b7a873;
}

.trendingTopicsList>a:nth-child(5) li {
  background-color: #9d73b7;
}

.trendingTopicsList>a:nth-child(6) li {
  background-color: #7ab3ba;
}

.removeLinkStyling{
  text-decoration: none;
  color: inherit;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}