@import "../../../assets/css/colors.scss";

.appQuizModalOverlay {
  width: 100%;
  min-height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  .appQuizModal {
    padding: 50px 30px;
    width: 520px;
    max-width: 520px;
    background: $newDarkBlue;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    .appQuizModalHeading {
      text-align: center;
      h2 {
        margin: 0;
        line-height: 1.2;
        color: $white;
        font-size: 30px;
      }
      p {
        margin: 40px 0;
        line-height: 1;
        color: $white;
        font-size: 24px;
      }
    }
    .continueBtnWrapper {
      margin: 30px 0 0 0;
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        width: 100%;
        height: 42px;
        border-radius: 4px;
        background-color: $newLightGrey;
        color: $white;
        font-size: 20px;
        transition: all 500ms ease-in-out;
        &:hover {
          background-color: darken($color: $newLightGrey, $amount: 10%);
          text-decoration: none;
        }
      }
    }
  }
}

@media (max-width: 544px) {
  .appQuizModalOverlay {
    .appQuizModal {
      padding: 30px 10px;
      width: 94%;
      max-width: 420px;
      .appQuizModalHeading {
        h2 {
          font-size: 27px;
          br {
            display: none;
          }
        }
        p {
          margin: 24px 0;
          font-size: 20px;
          br {
            display: none;
          }
        }
      }
      .continueBtnWrapper {
        margin: 26px 0 0 0;
      }
    }
  }
}
