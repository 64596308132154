@import "../../assets/css/colors.scss";

.container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  background-color: $newLightBlue;
}