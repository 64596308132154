@import "../../../assets/css/colors.scss";

.authBtnWrapper {
  display: flex;
  align-items: center;

  button {
    &:first-of-type {
      margin-right: 16px;
    }
  }
}

.authModalOverlay {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 300;
  display: flex;
  align-items: center;
  justify-content: center;

  .authModal {
    width: 410px;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 0 60px 4px $black;
    background: $newDarkBlue;

    .authHeader {
      width: 100%;
      padding: 10px 0 10px 0;
      display: flex;
      align-items: center;
      justify-content: center;

      a {
        text-decoration: none;
        color: $white;
        text-shadow: $pink 3px 3px 1px;
        font-size: 40px;
        font-weight: 700;
      }
    }
  }
}



.closeModalButton{
  background-color: transparent;
  width: 30px;
  box-shadow: none;
  color: lightgrey;
  justify-self: flex-end;
  font-size: 20px;
  padding-top: 10px;
  border: none;
  cursor: pointer;
  margin-left: 370px;
}

@media screen and (max-width: 985px) {
    .authModal {
      width: 94%;
    }

        .closeModalButton {
          background-color: transparent;
          width: 30px;
          box-shadow: none;
          color: lightgrey;
          justify-self: flex-end;
          font-size: 20px;
          padding-top: 10px;
          border: none;
          cursor: pointer;
          margin-left: 350px !important;
        }
}

.followButton{
  background-color: #8e92c0;
  color: white;
  margin-right: 20px;
  height: 38px;
  border: none;
  width: 100px;
  border-radius: 0;
  font-size: 20px;
  box-shadow: none;
  cursor: pointer;
}

.subscribeButton {
  background: linear-gradient(90deg,
      rgba(254, 96, 31, 1) 0%,
      rgba(234, 20, 65, 1) 52%,
      rgba(185, 18, 156, 1) 100%);

  &:hover {
    filter: brightness(200%);
  }
}

.joinButton{
  background-color: #8e92c0;
  color: white;
  margin-right: 20px;
  height: 30px;
  border: none;
  width: 116px;
  border-radius: 0;
  font-size: 14px;
  box-shadow: none;
  cursor: pointer;
  border-radius: 6px;
}

@media screen and (max-width: 500px) {
  .joinButton {
    width: 70px;
  }
}