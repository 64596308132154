/* homepage.module.scss */
@import "../../../assets/css/colors.scss";
/* homepage.module.scss */
.containerPadding{
  padding-left: 15px;
  background-color: transparent;
  padding-right: 15px;
  
}

.sidebar {
  flex: 0 0 200px;
  background-color: $newLightBlue;
  height: 100%;
  width: 200px;
  justify-content: center;
  margin-bottom: 10px;
  /* Set the height to 100% of the parent container (homepageContainer) */
}

.sidebarItem {
  cursor: pointer;
  padding: 5px 0px 5px 30px;
  font: 'Nunito Medium';
  font-weight: 500;
  font-size: 15pt;
  color: $newLightGrey;

}

.sidebarItem:hover {
  background-color: #b5bbe4;
}

.sidebarTitle {
  cursor: pointer;
  padding: 2px;
  padding-left: 5px;
  font: 'Nunito Medium';
  font-weight: 500;
  font-size: 18pt;
}

.sidebarTitle:hover {
  background-color: #b5bbe4;
}


.login {
  padding-top: 16px;
  padding-left: 40px;
  padding-bottom: 16px;
  font: 'Nunito Medium';
  font-weight: 500;
  font-size: 14pt;
  color: $newLightGrey;
}



.liveNowStreamersList {
  margin: 0 0 0 0;
  padding-left: 4px;

  cursor: pointer;
  list-style: none;
}

.liveNowStreamer {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  color: $newLightGrey;
  font-size: 15px;
  align-items: center;
}

ol .liveNowStreamer:last-child {
  padding-bottom: 16px;
}

.liveNowStreamer:hover {
  background-color: #b5bbe4;
}

.channelLogo {
  width: 15px;
  height: 15px;
  margin-right: 10px;
  border-radius: 50%;
  object-fit: cover;
}
