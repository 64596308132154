@import "../../../assets/css/colors.scss";

.userMenu {
  &.userMenuMobile {
    margin-right: 12px;
  }

  .avatarWrapper {
    position: relative;
    cursor: pointer;

    img {
      height: 35px;
      width: 35px;
      border-radius: 50%;
      box-shadow: 0px 0px 12px -2px $lightGreen;
      display: block;
      object-fit: cover;
    }

    i {
      padding: 4px 0 0 0;
      color: $white;
      font-size: 30px;
      line-height: 1;
    }

    .userMenuDropdown {
      min-width: 160px;
      width: 160px;
      background: $purple;
      position: absolute;
      right: 0;
      border-radius: 4px;
      box-shadow: 0 0 4px 1px rgba(0, 0, 0, 1);
      z-index: 50;
      overflow: hidden;

      .userMenuAvatarWrapper {
        padding: 8px 12px;
        border-radius: 4px;
        text-decoration: none;
        display: flex;
        align-items: center;
        background: $blue;

        &:hover {
          text-decoration: none;
        }

        .profileImg {
          margin-right: 10px;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          display: block;
          object-fit: cover;
        }

        i {
          margin-right: 10px;
          padding: 0;
          color: $white;
          font-size: 28px;
        }

        p {
          margin: 0;
          color: $white;
          font-weight: 700;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }

      .dropdownMenu {
        padding: 12px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        background: $white;

        a {
          text-decoration: none;
          color: $black;
          font-size: 18px;
          font-weight: 600;
          transition: all 500ms ease-in-out;

          &:hover {
            color: $blue;
          }

          span {
            &.friendsNum {
              color: red;
              font-weight: 700;
            }
          }
        }

        button {
          margin: 8px 0 0 0;
          border: none;
          outline: none;
          width: 100%;
          height: 32px;
          border-radius: 4px;
          background: $purple;
          color: $white;
          font-size: 16px;
          transition: all 500ms ease-in-out;
          cursor: pointer;

          &:hover {
            background: darken($color: $purple, $amount: 10%);
          }
        }
      }
    }

    .useNotificationDropDown {
      min-width: 300px;
      width: 300px;
      height: 360px;
      background: $blue;
      position: absolute;
      right: 40px;
      border-radius: 4px;
      box-shadow: 0 0 4px 1px rgba(0, 0, 0, 1);
      z-index: 50;
      overflow: hidden;

      .notificationHeading {
        padding: 8px 12px;
        border-radius: 4px;
        text-decoration: none;
        display: flex;
        align-items: center;
        background: $blue;
        color: $white;
        font-size: 20px;

        &:hover {
          text-decoration: none;
        }

        .profileImg {
          margin-right: 10px;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          display: block;
          object-fit: cover;
        }

        i {
          margin-right: 10px;
          padding: 0;
          color: $white;
          font-size: 28px;
        }

        p {
          margin: 0;
          color: $white;
          font-weight: 700;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }

      .dropdownMenu {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        background: $white;
        overflow-y: scroll;
        height: 85%;

        &::-webkit-scrollbar {
          display: none;
          -ms-overflow-style: none;
          /* IE and Edge */
          scrollbar-width: none;
          /* Firefox */
        }

        .notifWrapper {
          border-width: 2px 0px 0px 0px !important;
          // border-bottom: 1px;
          border: solid;
          border-color: lightgrey;
          &:hover {
            background: rgba(184, 182, 182, 0.274);
          }

          .notifFlex {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0 10px;

            // &:hover {
            // background: gray;

            // a {
            //   color: $white;
            // }
            // }

            a {
              padding: 12px;
              text-decoration: none;
              color: $black;
              font-size: 14px;
              font-weight: 600;
              transition: all 500ms ease-in-out;

              span {
                &.friendsNum {
                  color: red;
                  font-weight: 700;
                }
              }
            }

          }

          .inviteBtn {
            display: flex;
            flex-direction: row;
            gap: 5px;
            align-items: center;
            justify-content: space-evenly;
            margin: 15px;
            margin-top: 0;

            input[type=button] {
              border-width: 0px !important;
              &:hover {
                cursor: pointer;
              }
            }

            input[type=button].accept {
                    width: 80px !important;
                    border-radius: 10px;
                    outline: none;
                    height: 32px;
                    background: linear-gradient(130deg, #180a58, #293ea9);
                    color: #fff;
                    font-size: 14px;
                    cursor: pointer;
                    transition: all 500ms ease-in-out;
                    &:hover {
                        cursor: pointer;
                        filter: brightness(60%);
                    }
            }

            input[type=button].reject {
                    width: 80px !important;
                    border-radius: 10px;
                    outline: none;
                    height: 32px;
                    background: linear-gradient(90deg, #8f8d8d 37%, #b5b5b5 100%);
                    color: #fff;
                    font-size: 14px;
                    transition: all 500ms ease-in-out;
                    &:hover {
                        filter: brightness(70%) !important;
                    }
            } 
          }
        }
        .emptyNotification {
          display: flex;
          flex-direction: column;
          align-items: center;

          svg {
            font-size: 80px;
            color: $blue;
          }
          margin: auto;
        }

        button {
          margin: 8px 0 0 0;
          border: none;
          outline: none;
          width: 100%;
          height: 32px;
          border-radius: 4px;
          background: $purple;
          color: $white;
          font-size: 16px;
          transition: all 500ms ease-in-out;
          cursor: pointer;

          &:hover {
            background: darken($color: $purple, $amount: 10%);
          }
        }
      }
    }
  }
}

.flexRow{
  display: flex;
  flex-direction: row;
}